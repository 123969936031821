import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../styles/shapyaMaxWidth.css';
import './ProfileCeoCard.css';

const InvestmentOpportunitiesBody = () => {
  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth mainContainer">
      <div className="profile-card">
        <div className="profile-header">
          <div className="profile-img" />
          <h2 className="profile-name">
            {t('investmentOportunities.page.profileCard.name')}
          </h2>
          <p className="profile-title">
            {t('investmentOportunities.page.profileCard.title')}
          </p>
        </div>
        <div className="profile-info">
          <p className="profile-bio">
            {t('investmentOportunities.page.profileCard.bio')}
          </p>
          <p className="profile-bio">
            {t('investmentOportunities.page.profileCard.bio2')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvestmentOpportunitiesBody;
