import React from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import NavbarWhite from '../components/navbar/NavbarWhite';
import InvestmentOpportunitiesBody from '../components/investmentOpportunities/InvestmentOpportunitiesBody';
import Footer from '../components/Footer';

import '../i18n';
import '../components/layout.css';

function InvestmentOpportunities() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <SEO
        title={t('investmentOportunities.page.title')}
        description={t('investmentOportunities.page.title')}
      />
      <NavbarWhite />
      <InvestmentOpportunitiesBody />
      <Footer />
    </React.Fragment>
  );
}

export default InvestmentOpportunities;
