import React from 'react'
import { Grid } from '@material-ui/core';

import ProfileCeoCard from './profileCeoCard/ProfileCeoCard';
import InvestmentForm from './investmentForm/InvestmentForm';

import '../styles/shapyaMaxWidth.css';

function InvestmentOpportunitiesBody() {
  return (
    <div className="shapyaMaxWidth">
      <Grid>
        <ProfileCeoCard />
        <InvestmentForm />
      </Grid>
    </div>
  )
}

export default InvestmentOpportunitiesBody